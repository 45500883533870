import React from 'react';
import { Link } from 'react-router-dom';

const AboutUsPage: React.FC = () => {
  return (
    <main className="bg-white">
      <div className="px-4 pb-16 pt-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
          <div className="flex flex-col mb-10 sm:text-center sm:mb-0">
            <a href="/" className="mb-6 mx-auto">
              <div className="flex items-center justify-center w-12 h-12 rounded-full bg-indigo-50 text-brand-default">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                  />
                </svg>
              </div>
            </a>
            <div className="max-w-xl mb-10 mx-auto sm:text-center lg:max-w-2xl md:mb-8">
              <h2 className="max-w-lg text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                <span className="relative inline-block">
                  <svg
                    viewBox="0 0 52 24"
                    fill="currentColor"
                    className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-gray-7 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block">
                    <defs>
                      <pattern id="e77df901-b9d7-4b9b-822e-16b2d410795b" x="0" y="0" width=".135" height=".30">
                        <circle cx="1" cy="1" r=".7" />
                      </pattern>
                    </defs>
                    <rect fill="url(#e77df901-b9d7-4b9b-822e-16b2d410795b)" width="52" height="24" />
                  </svg>
                  <span className="relative">About</span>
                </span>{' '}
                us
              </h2>
            </div>
          </div>
          <div>
            <p className="mt-4  text-xl text-gray-500">
              ReHustle was built with the mission of enabling creators and new generation influencers with the tools to
              get going in their growth journey via tools and support so that they can focus on what they do best. We
              handle the technology and their digital presence in a way to be a one-stop solution for all their needs
            </p>
            <p className="mt-4  text-xl text-gray-500 py-8">
              We actively support self-employed, solopreneurs, small businesses in their initial and growth phase with
              respect to digital needs in a sustainable and economical way.
            </p>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AboutUsPage;
